<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Nickname"
              v-model="filter.nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="formattedStatusOptions"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="filterData">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <complete-table
          :loading="loading"
          :data="this.$store.getters.getterListPenunjang"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Project Code</span>
              </th>
              <th style="min-width: 350px">Project Name</th>
              <th style="min-width: 100px">Nickname</th>
              <th style="min-width: 250px">SOW / DL Scope</th>
              <th style="min-width: 250px">Order Design</th>
              <th style="min-width: 250px">BOM Base</th>
              <th style="min-width: 100px">DL Design</th>
              <th style="min-width: 100px">Submited Man Hour</th>
              <th style="min-width: 100px">Submited Plan Date</th>
              <th style="min-width: 100px">Total Design</th>
              <th style="min-width: 100px">DMP</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.name }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.nickname }}
                </span>
              </td>

              <td>
                <template v-if="item.sow_file_name">
                  <div class="d-flex">
                    <a
                      class="font-weight-bold d-block align-self-center  mr-3"
                      :href="item.sow_base_file_path"
                      target="blank"
                    >
                      {{ item.sow_file_name }}
                    </a>
                  </div>
                </template>

                <!--                    <template v-else>-->
                <input
                  :id="'fileUploadSow' + i"
                  @input="uploadFile($event, item, 'sow_file')"
                  type="file"
                  hidden
                />

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="uploadSow(i)"
                  :disabled="loadingUpload"
                >
                  <i class="flaticon-upload ml-3"></i>
                </a>

                <a
                  class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                  @click="
                    downloadFile(item.sow_file_path, item.sow_file_name, item)
                  "
                >
                  <i class="flaticon-download ml-3"></i>
                </a>
                <!--                    </template>-->
              </td>

              <td>
                <template v-if="item.order_design_file_path">
                  <div class="d-flex">
                    <a
                      class="font-weight-bold d-block align-self-center  mr-3"
                      :href="item.order_design_file_path"
                      target="blank"
                    >
                      {{ item.order_design_file_name }}
                    </a>
                  </div>
                </template>

                <!--                    <template v-else>-->
                <input
                  :id="'fileUploadOrder' + i"
                  @change="uploadFile($event, item, 'order_file')"
                  type="file"
                  hidden
                />

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="uploadOrder(i)"
                  :disabled="loadingUpload"
                >
                  <i class="flaticon-upload ml-3"></i>
                </a>

                <a
                  class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                  @click="
                    downloadFile(
                      item.order_design_file_path,
                      item.order_design_file_name,
                      item
                    )
                  "
                >
                  <i class="flaticon-download ml-3"></i>
                </a>
                <!--                    </template>-->
              </td>

              <td>
                <template v-if="item.bom_base_file_path">
                  <div class="d-flex">
                    <!--                    @click="-->
                    <!--                    openFile(-->
                    <!--                    item.bom_base_file_name,-->
                    <!--                    item,-->
                    <!--                    'bom',-->
                    <!--                    76,-->
                    <!--                    'bom_base_file_path'-->
                    <!--                    )-->
                    <!--                    "-->
                    <a
                      class="font-weight-bold d-block align-self-center mr-3 text-primary"
                      target="blank"
                      :href="item.bom_base_file_path"
                    >
                      {{ item.bom_base_file_name }}
                    </a>
                  </div>
                </template>

                <!--                    <template v-else>-->
                <input
                  :id="'fileUploadBom' + i"
                  @change="uploadFile($event, item, 'bom_file')"
                  type="file"
                  hidden
                />

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="uploadBom(i)"
                  :disabled="loadingUpload"
                >
                  <i class="flaticon-upload ml-3"></i>
                </a>

                <a
                  class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                  @click="
                    downloadFile(
                      item.bom_base_file_path,
                      item.bom_base_file_name,
                      item
                    )
                  "
                >
                  <i class="flaticon-download ml-3"></i>
                </a>
                <!--                    </template>-->
              </td>

              <td>
                <span class="text-muted font-weight-bold d-block">
                  <router-link
                    :to="'/project/dl/design/detail?nickname=' + item.nickname"
                    >DL Design</router-link
                  >
                </span>
              </td>

              <td>
                <a
                  class="font-weight-bold d-block"
                  href="#"
                  @click="showSubmitedManHourModal(item)"
                >
                  {{ item.submited_man_hour }}
                </a>
              </td>

              <td>
                <a
                  class="font-weight-bold d-block"
                  href="#"
                  @click="showSubmitedManHourModal(item)"
                >
                  {{ item.submited_plan_date }}
                </a>
              </td>

              <td>
                <a
                  class="font-weight-bold d-block"
                  href="#"
                  @click="showSubmitedManHourModal(item)"
                >
                  {{ item.total_design }}
                </a>
              </td>

              <td>
                <span class="text-muted font-weight-bold d-block">
                  {{ item.revision_dmp }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal
      ref="detail-man-hour"
      hide-footer
      title="Detail Man Hour & Plan date"
    >
      <div class="d-block text-center">
        <complete-table :data="detailDocuments" :loading="loading">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 100px" class="pl-7">
                <span class="text-dark-75">Scope</span>
              </th>
              <th style="min-width: 100px">Submited Man Hour</th>
              <th style="min-width: 100px">Submited Plan Date</th>
              <th style="min-width: 100px">Total Design</th>
            </tr>
          </template>

          <template #defaultBody="{item, i}">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.scope ? item.scope.name : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.manhour }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.plan_date }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.total_design }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CHECKLIST_PENUNJANG } from "@/core/services/store/document-penunjang.module";
import {
  GET_SUMMARY_CHECKLIST_PENUNJANG,
  POST_DOCUMENT_FORM,
  UPDATE_DOCUMENT_FORM
} from "../../../core/services/store/document-penunjang.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { GET_STATUS_OPTION } from "../../../core/services/store/status.module";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import ApiService from "../../../core/services/api.service";
import { showToast } from "../../../core/helper/toast.helper";

export default {
  name: "CheckListDocument",
  components: { CompleteTable },
  data() {
    return {
      filter: {
        status: null,
        name: null,
        code: null,
        owner: null,
        contractNumber: null,
        nickname: null
      },
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ]
      },
      selectedProject: null,
      sow_link: "https://www.google.com/",
      data: {
        projects: [
          {
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            nickname: "MAKPAR 1",
            sow_link: "https://www.google.com/",
            order_design_link: null,
            bom_base_link: null,
            dl_design: "DL Design",
            submited_man_hour: "40",
            submited_plan_date: "50",
            total_design: "100",
            dmp: "Rev 1",
            submited_man_hours: [
              {
                scope: "HMI",
                submited_man_hour: "40",
                submited_plan_date: "20",
                total_design: "80"
              },
              {
                scope: "Circuit",
                submited_man_hour: "40",
                submited_plan_date: "20",
                total_design: "80"
              },
              {
                scope: "Signal",
                submited_man_hour: "40",
                submited_plan_date: "20",
                total_design: "80"
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      loadingUpload: state => state.dokumenPenunjang.loadingUpload,
      loading: state => state.dokumenPenunjang.loading,
      detailDocuments: state => state.dokumenPenunjang.detail_document,
      statuses: state => state.status.status?.data
    }),

    formattedStatusOptions() {
      let status = [];

      if (arrayMoreThanOne(this.statuses)) {
        status = [
          {
            value: "open",
            text: "Open"
          },
          {
            value: "close",
            text: "Close"
          }
        ];
      }

      return [{ value: null, text: "Choose Status" }, ...status];
    }
  },
  methods: {
    uploadBom(i) {
      document.getElementById("fileUploadBom" + i).click();
    },

    uploadSow(i) {
      document.getElementById("fileUploadSow" + i).click();
    },

    uploadOrder(i) {
      document.getElementById("fileUploadOrder" + i).click();
    },

    uploadFile(value, item, type) {
      const file = value.target.files[0];

      const keys = ["sow_file", "bom_file", "order_file"];

      let form = new FormData();

      keys.forEach(key => {
        form.append(key, type === key ? file : null);
      });

      form.append("code_project", item?.code);

      this.$store.dispatch(UPDATE_DOCUMENT_FORM, form);
    },

    deleteFile(index, key) {
      this.data.projects[index][key] = null;
    },

    showSubmitedManHourModal(selectedProject) {
      this.selectedProject = selectedProject;

      this.$store.dispatch(GET_SUMMARY_CHECKLIST_PENUNJANG, {
        nickname: selectedProject?.code
      });

      this.$refs["detail-man-hour"].show();
    },

    openFile(fileName, item, type, folderId, key) {
      this.$store
        .dispatch(POST_DOCUMENT_FORM, {
          project_code: item?.code,
          server_type: "DEV",
          folder_id: folderId,
          filename: fileName
        })
        .then(response => {
          Promise.resolve();

          const data = response.data.data;

          window.location.href = `ftp://${data.ftp.username}:${data.ftp.password}@${data.ftp.server}${item[key]}`;
        });
    },

    closeSubmitedManHourModal() {
      this.selectedProject = null;

      this.$refs["detail-man-hour"].hide();
    },

    search() {
      const nickname = this.filter.nickname;

      let newNick = "";

      if (nickname != null) {
        newNick = nickname.toLowerCase();
      } else {
        newNick = null;
      }

      const result = this.$store.getters.getterMasterPenunjang;

      result.filter(res => res.nickname.toLowerCase().includes(newNick));
    },

    filterData() {
      this.$store.dispatch(GET_CHECKLIST_PENUNJANG, {
        jobdesk: "design",
        ...this.filter
      });
    },

    downloadFile(url, fileName, item) {
      if (
        item.sow_file_path == "" ||
        item.order_design_file_path == "" ||
        item.bom_base_file_path == ""
      ){
        showToast("Warning", "Please Upload all Three Document", "danger");
        return;
      }
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: url
      })
        .then(response => {
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );

          const fileLink = document.createElement("a");

          fileLink.href = fileURL;

          fileLink.setAttribute("download", fileName);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          showToast("Error", "Download Failed, Please Reupload File", "danger");
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Checklist Supporting Document" }
    ]);

    this.$store.dispatch(GET_CHECKLIST_PENUNJANG, { jobdesk: "design" });

    this.$store.dispatch(GET_STATUS_OPTION);

    //console.log('DATA',this.$store.getters.getterListPenunjang)
  }
};
</script>

<style></style>
